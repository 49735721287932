/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import loadable from "@loadable/component"
import { Global } from "@emotion/core"
import { mediaQueries } from "gatsby-design-tokens/dist/theme-gatsbyjs-org"

import { PluginsSidebarWidth, HeaderHeight } from "./shared/constants/layout"

const PluginSearchBar = loadable(() => import(`./plugin-searchbar-body`))

const PageWithPluginSearchBar = ({
  location,
  children,
  isPluginsIndex,
  newPluginPage,
}) => (
  <Fragment>
    <Global
      styles={{
        // ensure anchor link targets are not covered by <Header isFixed>
        // https://caniuse.com/?search=scroll-padding-top
        //
        // - also add a bit of extra whitespace via calc
        // - breakpoint should match
        //   - components/PageHeader/ `isFixed`
        //     (where we use a different theme, mediaQueries.tablet luckily matches `md`)
        //     (^ see https://gatsby-interface.netlify.app/?path=/docs/theme-media-queries--page)
        //   - components/sidebar/sidebar (where we use the old gatsbyjs.org theme tokens, like here)
        [mediaQueries.md]: {
          html: { scrollPaddingTop: `calc(${HeaderHeight} + 1.5rem)` },
        },
      }}
    />
    <nav
      css={theme => ({
        // mobile: hide PluginSearchBar when on gatsbyjs.org/packages/foo, aka package README page
        display: !isPluginsIndex ? `none` : false,
        height: newPluginPage
          ? `calc(100vh - ${HeaderHeight} - ${theme.space[10]})`
          : `calc(100vh - ${HeaderHeight})`,
        top: newPluginPage
          ? `calc(${theme.space[10]} + ${HeaderHeight})`
          : HeaderHeight,
        width: `100%`,
        zIndex: 1,
        [mediaQueries.md]: {
          backgroundColor: `primaryBackground`,
          display: `block`,
          position: `fixed`,
          width: PluginsSidebarWidth.default,
        },
        [mediaQueries.lg]: {
          width: PluginsSidebarWidth.large,
        },
      })}
      aria-label="Plugin navigation"
    >
      <PluginSearchBar location={location} />
    </nav>
    <main
      id="plugin-main"
      sx={{
        // mobile: hide README on gatsbyjs.org/plugins index page
        display: isPluginsIndex ? `none` : false,
        [mediaQueries.md]: {
          display: `block`,
          pl: PluginsSidebarWidth.default,
        },
        [mediaQueries.lg]: {
          pl: PluginsSidebarWidth.large,
        },
        // push down content to account for the space that <PageHeader isFixed> will use
        pt: [null, null, null, HeaderHeight],
      }}
    >
      {children}
    </main>
  </Fragment>
)

export default PageWithPluginSearchBar
